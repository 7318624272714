<template>
	<div class="notice">

		<div class="searchfor">
			<div class="inp">
				<el-input placeholder="关键字" class="level" v-model="keyword">
					<template slot="prefix">
						<span class="searchforlogo" style="width: 22PX;height: 27PX;">
							<img src="../assets/image/name.png" alt="" srcset="">
						</span>
					</template>
				</el-input>
			</div>
			<el-select placeholder="栏目" class="level" filterable v-model="lmid" clearable>
				<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
				</el-option>
				<template slot="prefix">
					<span class="searchforlogo">
						<img src="../assets/image/project2.png" alt="" srcset="">
					</span>
				</template>
			</el-select>
			<div class="but" @click="searchfor">
				<div class="imgz">
					<img src="../assets/image/searchfor.png" alt="">
				</div>
				<div class="text">搜索</div>
			</div>
		</div>

		<div class="content">
			<div class="specific">
				<ul>
					<li v-for="(item,index) in date" :key="index" v-if="template == 'date_list'">
						<div @click="jump(item.id)" style="height: 72PX;">
							<div class="news-date">
								<b>{{item.publishTime.split(" ")[0].split("-")[2]}}</b>
								<p>{{item.publishTime.split(" ")[0].split("-")[0]}}-{{item.publishTime.split(" ")[0].split("-")[1]}}
								</p>
							</div>
							<h2>
								{{item.title}}
							</h2>
							<p class="news-content">
								{{getText(item.content)}}
							</p>
						</div>
						<div class="horizontalline horizon"
							v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div>
					</li>
					<li v-for="(item,index) in date" :key="index" v-if="template == 'picture'">
						<div class="news_item">
							<a @click="jump(item.id)">
								<img :src="item.imgUrl">
								<h3>
									<div class="pic-title">
										{{item.title}}
									</div>
								</h3>
							</a>
						</div>
						<div class="horizontalline horizon"
							v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div>
					</li>
					<li v-for="(item, index) in date" :key="index" class="nli" @click="jump(item.id)"
						v-if="template == 'picture_list'">
						<div>
							<div class="img" v-if="item.imgUrl">
								<img :src="item.imgUrl" alt="">
							</div>
							<div class="img" v-else>
								<img src="../assets/image/placeholder.png" alt="">
							</div>
							<h2 class="title2">
								{{item.title}}
							</h2>
							<div class="img_content">
								{{getText(item.content)}}
							</div>
							<span class="date">
								{{item.createTime.split(" ")[0]}}
							</span>
						</div>
						<div style="margin-top: 43PX" class="horizontalline horizon"
							v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div>
					</li>
					<li v-for="(item,index) in date" :key="index" @click="jump(item.id)"
						v-if="template == 'common_list'">
						<div style="display: flex;justify-content: space-between;">
							<div class="rounddot">
								<div class="round"></div>
								<a class="roundtitle">
									{{item.title}}
								</a>
							</div>
							<span>[{{item.publishTime | format}}]</span>
						</div>
						<div class="horizontalline horizon"
							v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div>
					</li>
					<li v-for="(item,index) in date" :key="index" @click="jump(item.id)"
						v-if="template == 'picture_column'">
						<div class="pic_item">
							<div class="item-box">
								<a><img :src="item.imgUrl" /></a>
								<a>
									<div class="pic_title">{{item.title}}</div>
								</a>
							</div>
						</div>
						<div class="horizontalline horizon"
							v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div>
					</li>
					<li v-for="(item,index) in date" :key="index"
						v-if="template != 'picture_column' && template != 'common_list'&& template != 'picture_list'&& template != 'picture'&& template != 'date_list'">
						<div style="display: flex;justify-content: space-between;">
							<div class="rounddot">
								<div class="round"></div>
								<a class="roundtitle" @click="jump(item.id)">
									{{item.title}}
								</a>
							</div>
							<span>[{{item.publishTime | format}}]</span>
						</div>
						<div class="horizontalline horizon"
							v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div>
					</li>
				</ul>
			</div>
			<div class="horizontalline" v-if="date && date.length > 0 && !Number.isInteger(Number(date.length) / 5)">
			</div>
		</div>
		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :go="20" :pageSize="20" :pageNo="pageNum"></pagination>
		</div>

	</div>
</template>
<script>
	export default {
		name: 'notice',
		components: {},
		data() {
			return {
				id: 718,
				pageNum: 1,
				pageSize: 20,
				total: 0,
				date: [],
				template: '',
				title: '',
				options: [],
				keyword:'',
				lmid:null
			}
		},
		filters: {
			format(x) {
				if(x != null && x != ''){
					return x.split(" ")[0]
				}
			}
		},
		created() {
			this.keyword = this.$route.query.keyword
			this.search()
			this.columnList()
		},
		methods: {
			columnList(){
				this.$api.columnList().then(res=>{
					this.options = res.data.data
				})
			},
			currentchange(val) {
				this.pageNum = val
				this.search()
			},
			searchfor(){
				this.pageNum = 1
				this.search()
			},
			search() {
				let data = {
					keyword:this.keyword ,
					pageNo: this.pageNum,
					pageSize: this.pageSize,
					id:this.lmid
				}
				this.$api.search(data).then(res => {
					this.total = res.data.data.total
					this.date = res.data.data.records
					this.date.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
				})
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/articleDetails',
					query: {
						id: id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
			getText(str) {
				let hasChineseFontFamily = /font-family:\s*[^;]*[\u4E00-\u9FFF]+/g.test(str)
				if (hasChineseFontFamily) {
				  str = str.replace(/font-family:\s*[^;]*[\u4E00-\u9FFF]+/g, "font-family: ''");
				}
				let res = str.replace(/[^\u4e00-\u9fa5]/gi, "").replace("微软雅黑", "")
				if (res.length > 80) {
					res = res.slice(0, 80) + '...'
				}
				return res
			},
		}
	}
</script>
<style lang="scss" scoped>
	.notice {
		width: 1200PX;
		margin: 0 auto;
	}

	.header {
		display: flex;
		margin-top: 34PX;
		margin-left: 63PX;
		font-size: 18PX;
		font-weight: 500;
		color: #333333;
		margin-bottom: 23PX;
		cursor: pointer;
	}

	.current {
		color: #0066FF;
	}

	.content {
		width: 1200PX;
		margin: 0 auto;
	}

	.horizontalline {
		border: 1PX dashed #BFBFBF;
		margin-bottom: 23PX;
	}

	.specific {
		font-size: 15PX;
		color: #333333;
		cursor: pointer;
	}
	
	.specific ul li {
	
		margin-bottom: 23PX;
	}
	
	.rounddot {
		display: flex;
		align-items: center
	}

	.round {
		width: 7PX;
		height: 7PX;
		border-radius: 100%;
		background: #000;
		box-shadow: 0 2PX 4PX 0 rgba(0, 0, 0, .2);
		margin-right: 10PX;
	}

	.roundtitle {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		width: 1000PX;
		cursor: pointer;
	}

	.paging {
		margin-top: 81PX;
		margin-bottom: 132PX;
		display: flex;
		align-items: center;
	}

	.paging span {
		margin-right: 5PX;
	}

	::v-deep .el-pager li.active {
		color: #003399;
	}

	.news-date {
		width: 72PX;
		height: 72PX;
		border: 1PX solid #bfd4e9;
		float: left;
		margin-right: 30PX;
		font-family: arial;
		color: #6198e2;
		background: #fff;
		text-align: center;
		transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
	}

	.news-date b {
		height: 43PX;
		line-height: 42PX;
		font-size: 26PX;
	}

	.news-date p {
		font-size: 14PX;
		margin: 0 5PX;
		height: 26PX;
		line-height: 26PX;
		color: #6198e2;
		background: #fff;
		border-top: 1PX solid #dfe7f3;
		transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
	}

	.specific li h2 {
		overflow: hidden;
		font-size: 16PX;
		color: #333;
		font-weight: 700;
		height: 22PX;
		line-height: 22PX;
		margin-bottom: 8PX;
		margin-top: 0;
	}

	.specific li:hover a {
		color: #275caa;
	}

	.specific li:hover .news-date {
		border: 1PX solid #275caa;
		background: #275caa;
		color: #fff;
	}

	.specific li:hover .news-date p {
		color: #fff;
		background: #275caa;
	}

	.news_item {
		width: 30%;
		margin-top: 28PX;
		margin-right: 30PX;
	}

	.news_item img {
		width: 100%;
		height: 164PX;
	}

	.news_item h3 {
		font-size: 14PX;
		height: 54PX;
		line-height: 54PX;
		font-weight: 400;
		text-align: center;
		overflow: hidden;
	}

	.news_item:nth-child(3n) {
		margin-right: 0;
	}

	a:hover {
		color: #275caa;
	}

	.nli {
		margin: 30PX 18PX 0;
		overflow: hidden;
		padding-bottom: 30PX;
		display: block !important;
	}

	.img {
		width: 200PX;
		height: 133PX;
		float: left;
		margin-right: 30PX;
		background: #fff;
	}

	.img img {
		margin: 0 auto;
		width: 200PX;
		height: 133PX;
		border: 0;
		display: block;
	}

	.title2 {
		height: 30PX;
		line-height: 30PX;
		overflow: hidden;
		font-size: 16PX;
		color: #333;
		font-weight: 700;
		margin-bottom: 10PX;
		margin-top: 5PX;
	}

	.date {
		color: #999;
		display: block;
		padding: 10PX 0 0;
	}

	.img_content {
		height: 40PX;
		overflow: hidden;
	}

	ul {
		margin-bottom: 30PX;
	}

	.news-text li:hover h2 {
		color: #275caa;
	}

	h2 {
		cursor: pointer;
	}

	.pic_item {
		width: 33.3%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 20PX;
	}

	.item-box {
		width: 317PX;
		height: 257PX;
	}

	.pic_title {
		text-align: center;
		margin-top: 20PX;
		cursor: pointer;
		font-size: 16PX;
	}

	.searchfor {
		width: 1200PX;
		height: 123PX;
		background: rgba(216, 233, 255, 0.38);
		border-radius: 7PX;
		margin-top: 29PX;
		display: flex;
		margin-bottom: 50PX;
	}

	.searchfor ::v-deep .el-input__inner {
		width: 227PX;
		height: 43PX;
		background: #FFFFFF;
		border: 1PX solid #003680;
		border-radius: 7PX;
		font-size: 18PX;
		color: #333333;
		padding: 0 15PX 0 57PX;
	}

	.searchfor ::v-deep input::-webkit-input-placeholder {
		color: #333333;
	}

	.searchfor ::v-deep input::-moz-input-placeholder {
		color: #333333;
	}

	.searchfor ::v-deep input::-ms-input-placeholder {
		color: #333333;
	}

	.searchfor ::v-deep .el-select .el-input .el-select__caret {
		color: #191919;
	}

	.searchfor .searchforlogo {
		display: block;
		width: 28PX;
		height: 27PX;
		margin-top: 8PX;
		margin-left: 17PX;
	}

	.searchfor .searchforlogo img {
		width: 100%;
		height: 100%;
	}

	.searchfor .inp ::v-deep .el-input__inner {
		width: 201PX;
		height: 43PX;
	}

	.but {
		width: 150PX;
		height: 43PX;
		background: #164B92;
		border-radius: 9PX;
		display: flex;
		align-items: center;
		margin-top: 41PX;
		margin-left: 26PX;
		cursor: pointer;
	}

	.but .imgz {
		width: 21PX;
		height: 21PX;
		margin-left: 31PX;
	}

	.but .imgz img {
		width: 100%;
		height: 100%;
	}

	.but .text {
		font-size: 19PX;
		font-weight: bold;
		color: #FFFFFF;
		margin-left: 7PX;
		letter-spacing: 4PX;
	}

	.level {
		margin-top: 41PX;
		margin-left: 26PX;
	}
	
	.horizon {
		border: 1PX dashed #BFBFBF;
		margin-bottom: 23PX;
		margin-top: 23PX;
		padding: 0 53PX;
		margin-left: 0;
	}
</style>